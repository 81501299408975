(function() {
    const axios = require('axios');

    const getRootDomainFor = function(env) {
        switch (env) {
            case 'dev':
                return 'helpcenter-io.test';
            case 'staging':
                return 'helpcenter-io-staging.com';
            case 'prod':
                return 'helpcenter.io';
            default:
                return 'helpcenter.io';
        }
    };

    let options = window.hcOptions || {};
    let style = {
        position: 'fixed',
        bottom: '25px',
        right: options.position === 'bottom-left' ? 'auto' : '25px',
        left: options.position === 'bottom-left' ? '25px' : 'auto',
        width: '60px',
        height: '60px',
        display: 'block',
        visibility: 'visible',
        'max-height': '700px',
        'max-width': '100vw',
        opacity: 1,
        'z-index': 2147483647,
        outline: 'none',
        border: '0 none',
        transition: 'height ease-in-out .3s'
    };
    let widgetIframe = document.createElement('iframe');
    const clientWidth = window.innerWidth;
    let mobileStyle = clientWidth < 450;
    let isOpen = false;
    let widthTimeout = null;

    const setStyle = function(mobile, opened, data) {
        data = data || {};
        if (widthTimeout) clearTimeout(widthTimeout);
        if (opened) {
            widgetIframe.style['display'] = 'block';
            if (mobile) {
                widgetIframe.style.width = clientWidth + 'px';
                widgetIframe.style.height = window.innerHeight + 'px';
                widgetIframe.style.left = '0px';
                widgetIframe.style.bottom = '0px';
                widgetIframe.style.right = '0px';
                widgetIframe.style['max-height'] = '100vh';
                widgetIframe.style['min-width'] = '100vw';
            } else {
                if (window.hcOptions['showButton'] === false) {
                    widgetIframe.style.width = '410px';
                    widgetIframe.style.height = '100vh';
                    widgetIframe.style.right = '0px';
                    widgetIframe.style.top = '0px';
                    widgetIframe.style.bottom = '0px';
                    widgetIframe.style['max-height'] = '100vh';
                } else {
                    widgetIframe.style.width = data.width || '410px';
                    widgetIframe.style.height = data.height || 'calc(100% - 30px)';
                    widgetIframe.style.bottom = '25px';
                    
                    if (options.position === 'bottom-left') {
                        widgetIframe.style.left = '25px';
                    } else {
                        widgetIframe.style.right = '25px';
                        widgetIframe.style.left = 'auto';
                    }
                    
                    widgetIframe.style.top = 'auto';
                    widgetIframe.style['max-height'] = style['max-height'];
                    widgetIframe.style['min-width'] = 'auto';
                }
            }
        } else {
            widgetIframe.style.height = '60px';
            widgetIframe.style.bottom = '25px';

            if (options.position === 'bottom-left') {
                widgetIframe.style.left = '25px';
            } else {
                widgetIframe.style.right = '25px';
                widgetIframe.style.left = 'auto';
            }
         
            widgetIframe.style['max-height'] = style['max-height'];
            widgetIframe.style['min-width'] = '60px';
            widthTimeout = setTimeout(function() {
                widgetIframe.style.width = '60px';
            }, 330);
            if (window.hcOptions['showButton'] === false) {
                widgetIframe.style['display'] = 'none';
            }
        }
    };

    const rootDomain = options.env ? getRootDomainFor(options.env) : 'helpcenter.io';
    if (options.widgetEnabled !== false) {
        const setEventHandler = function () {
            if (!options.category && !options.href) {
                widgetIframe.contentWindow.postMessage({type: 'content', data: document.body.innerText}, '*');
            }

            // Here "addEventListener" is for standards-compliant web browsers and "attachEvent" is for IE Browsers.
            const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            const eventer = window[eventMethod];

            const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

            // Listen to message from child IFrame window
            eventer(messageEvent, function (e) {
                if (e.origin === 'https://embed.' + rootDomain) {
                    if (typeof e.data.type !== 'undefined' && e.data.type === 'event') {
                        if (e.data.event === 'nothing-found' && typeof options.onContactsRequest === 'function') {
                            options.onContactsRequest();
                        }
                    } else {
                        isOpen = e.data.open;
                        if (e.data) {
                            setStyle(mobileStyle, isOpen, e.data);
                        }
                    }
                }
            }, false);
        };

        if (options.showButton === false) {
            style['display'] = 'none';
        }

        const contactsHandled = typeof options.onContactsRequest === 'function';
        if (contactsHandled) {
            style['z-index'] = 2147482999; //2147483646;
        }
        let styleString = '';
        Object.keys(style).forEach(key => {
            const val = style[key];
            styleString += key + ':' + val + ';'
        });
        widgetIframe.style = styleString;
        widgetIframe.frameborder = "0";
        widgetIframe.src = "https://embed." + rootDomain + "/widget/" + options['app_id'] + '?mobile=' + mobileStyle + '&chndld=' + (contactsHandled ? 1 : 0);
        if (options.category) {
            widgetIframe.src += '&get=' + options.category;
        }

        if (options.href) {
            widgetIframe.src += '&href=' + options.href;
        }

        if (options.showButton === false) {
            widgetIframe.src += '&stealth=1';
        }

        if (options.lang) {
            widgetIframe.src += '&lang=' + options.lang;
        }

        if (options.position) {
            widgetIframe.src += '&pos=' + options.position;
        }

        widgetIframe.onload = setEventHandler;
        document.body.appendChild(widgetIframe);

        window.onresize = (e) => {
            mobileStyle = document.body.clientWidth < 450;
            if (!mobileStyle) {
                widgetIframe.contentWindow.postMessage('mobile=off', '*');
                setStyle(mobileStyle, isOpen);
            } else {
                widgetIframe.contentWindow.postMessage('mobile=on', '*');
                setStyle(mobileStyle, isOpen);
            }
        };

        const onSpecialLinkClick = (e) => {
            e.preventDefault();
            const origHref = e.target.href;
            if (origHref.indexOf('/content/') !== -1) {
                const href = origHref.slice(origHref.indexOf('/content/'));
                widgetIframe.contentWindow.postMessage({type: 'href', data: href, shouldOpen: true}, '*');
                return;
            } else {
                console.log('Unsupported link.');
                return;
            }
        };

        const hcLinks = document.querySelectorAll("[data-hc-article]");
        hcLinks.forEach(link => {
            link.addEventListener('click', onSpecialLinkClick);
        });

        window.hcWidget = {
            setCategory: function(categoryId) {
                widgetIframe.contentWindow.postMessage({type: 'category', data: categoryId}, '*');
            },
            setHref: function(href, shouldOpen) {
                shouldOpen = shouldOpen || false;
                widgetIframe.contentWindow.postMessage({type: 'href', data: href, shouldOpen: shouldOpen}, '*');
            },
            setHrefAndOpen(href) {
                this.setHref(href, true);
            },
            show: function() {
                widgetIframe.contentWindow.postMessage({state: 'open'}, '*');
            },
            hide: function() {
                widgetIframe.contentWindow.postMessage({state: 'close'}, '*');
            },
            hideAll: function() {
                widgetIframe.style['display'] = 'none';
            }
        }
    }

    const faqSections = document.getElementsByClassName('hc-faq-section');
    if (faqSections.length) {
        if (options['staticFaqS']) {
            faqSections.forEach(section => {
                const sectionId = section.getAttribute('data-id');
                axios.get('https://embed.' + rootDomain + '/widget/faqs/' + sectionId + '?html=1' + (options.lang ? '&lang=' + options['lang'] : '')).then(resp => {
                    if (resp.status === 200) {
                        section.innerHTML = resp.data;
                    } else {
                        console.error('FAQ section ' + sectionId + ' not allowed on this page.');
                    }
                });
            });
        } else {
            const faqSectionsLoader = document.createElement('script');
            faqSectionsLoader.src = 'https://' + rootDomain + '/js/faqs.js';
            faqSectionsLoader.async = true;
            document.body.appendChild(faqSectionsLoader);
        }
    }

    const embeddableHelpCenter = document.getElementById('hcio-embedded-help-center');
    if (embeddableHelpCenter) {
        const helpCenterLoader = document.createElement('script');
        helpCenterLoader.src = 'https://' + rootDomain + '/js/embeddable.js';
        helpCenterLoader.async = true;
        document.body.appendChild(helpCenterLoader);
    }
})();